& {
	font-size: 15px;

	&:not(.home) {
		.main {
			position: relative;
			padding: 1px 0 6vh 0;
			background: url("/images/bg-visual-branches-light.svg") $cream;

			&::before {
				content: url("/images/visual-tree.svg");
				position: absolute;
				right: 0;
				bottom: 0;
				font-size: 0;
				line-height: 0;
			}
		}
	}
}

// container
.container {
	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}
}

// headings
.mega-heading {
	font-size: calc(1.45rem + 2vw);

	@include media-breakpoint-up(xl) {
		font-size: 60px;
	}
}

.page-title {
	h1 {
		@include media-breakpoint-up(xl) {
			font-size: 50px;
		}
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;
	border-radius: 20px;
	background-color: $blue-lighter;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		.gallery-link {
			border-radius: 20px;

			.gallery-label {
				@extend .btn;
				top: 15px;
				left: 15px;
				border-radius: 6px;
				background-color: $blue;
				color: $white;

				&:hover {
					color: $white;
				}
			}
		}
	}
}

// default-card
.default-card {
	border-radius: 20px;
	border: none;
	.card-subtitle {
		color: $orange;
		font-weight: 700;
		font-size: 1rem;
	}
}

// bg-visual-dark
.bg-visual-dark {
	position: relative;
	padding: 1px 0;
	background: url("/images/bg-visual-branches.svg") $blue-light;
	overflow: hidden;

	&::after {
		content: url("/images/visual-tree.svg");
		position: absolute;
		right: 0;
		bottom: 0;
		font-size: 0;
		line-height: 0;
		transform-origin: bottom right;

		@media screen and (max-width: 1365px) {
			transform: scale(0.75);
		}

		@include media-breakpoint-down(lg) {
			transform: scale(0.6);
		}
	}

	.container-holder {
		.column {
			z-index: 1;
		}
	}
}

// bg-visual-light
.bg-visual-light {
	position: relative;
	padding: 1px 0 6vh 0;
	background: url("/images/bg-visual-leaves.svg") $cream;
	overflow: hidden;

	&::before {
		content: url("/images/visual-tree-left.svg");
		position: absolute;
		left: 0;
		bottom: 0;
		font-size: 0;
		line-height: 0;
		transform-origin: bottom left;

		@media screen and (max-width: 1365px) {
			transform: scale(0.75);
		}

		@include media-breakpoint-down(lg) {
			transform: scale(0.5);
		}

		@include media-breakpoint-down(md) {
			transform: scale(0.25);
		}
	}

	&::after {
		content: url("/images/visual-tree-right.svg");
		position: absolute;
		right: 0;
		bottom: 0;
		font-size: 0;
		line-height: 0;
		transform-origin: bottom right;

		@media screen and (max-width: 1365px) {
			transform: scale(0.75);
		}

		@include media-breakpoint-down(lg) {
			transform: scale(0.5);
		}

		@include media-breakpoint-down(md) {
			transform: scale(0.25);
		}
	}

	.container-holder {
		.column {
			z-index: 1;
		}
	}
}

// accommodation-category-overview
&.accommodation-category-overview,
&.accommodation-category-detail,
&.accommodation-detail,
&.accommodation-search-book,
&.accommodation-book {
	.eyecatcher {
		&::after {
			content: url("/images/wave-top.svg");
			z-index: 10;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			font-size: 0;
			line-height: 0;
		}
	}

	.lead-section {
		&::before {
			content: url("/images/wave-bottom.svg");
			z-index: 10;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			font-size: 0;
			line-height: 0;
		}
	}
}

// accommodation-detail
&.accommodation-detail {
	.main {
		padding-bottom: 0;
	}
}
