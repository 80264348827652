// header
.header {
	z-index: 998;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 15px 0;
	transition: 0.5s;

	@include media-breakpoint-up(xl) {
		position: fixed;
		top: 15px;
		left: 15px;
		right: 15px;
		padding: 17px;
	}

	&.sticky {
		top: 30px;
		left: 30px;
		right: 30px;
		background-color: $white;
		box-shadow: $shadow;
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		z-index: 997;
		position: fixed;
		top: 10px;
		left: 10px;

		.navbar-toggler {
			width: 48px;
			height: 48px;
			padding: 0;
			border: none;
			border-radius: 100%;
			background: $white;
			color: $orange;
			font-size: 18px;
			transition: 0.5s;

			i {
				line-height: 48px;
				transition: transform 0.5s;
			}

			&[aria-expanded="true"] {
				i {
					transform: rotate(180deg);

					&::before {
						content: "\f00d";
					}
				}
			}

			&:hover {
				background: $orange;
				color: $white;
			}
		}
	}

	// logo
	.logo {
		width: 200px;
		margin-right: auto;
		transition: 0.5s;

		@media (max-width: 399px) {
			width: 180px;
		}

		@include media-breakpoint-down(lg) {
			margin-bottom: -36px;
			transform: translateY(50px);
		}

		@include media-breakpoint-up(xl) {
			width: 260px;
			margin-bottom: -19px;
		}

		a {
			display: block;

			img {
				@extend .w-100;
			}
		}

		&.logo-black {
			display: none;
		}
	}

	&.sticky {
		.logo {
			&.logo-white {
				display: none;
			}

			&.logo-black {
				display: block;

				@include media-breakpoint-up(xl) {
					width: 225px;
					margin: -10px auto -35px 0;
				}
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			position: fixed;
			order: 5;
			top: 68px;
			left: 15px;
			right: 15px;
			border-radius: 20px;
			background-color: $cream;
			padding: 10px 15px;
			font-family: $font-family-secondary;

			.navbar-nav {
				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $black;
						font-size: 16px;
						font-weight: 700;

						&:hover {
							color: $orange;
						}
					}

					&.active {
						>.nav-link {
							color: $orange;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						border-radius: 6px;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 15px;

							.nav-link {
								color: $black;

								&:hover {
									color: $orange;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $orange;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			flex-grow: 0;

			.navbar-nav {
				>.nav-item {
					margin-right: 15px;
					font-size: 14px;
					font-weight: 700;

					>.nav-link {
						padding: 0.45rem 0.5rem !important;
						color: $white;

						&:hover {
							color: $orange;
						}
					}

					&.active {
						>.nav-link {
							color: $orange;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;
						background-color: $blue-lighter;
						border-color: $blue-lighter;

						&::before,
						&::after {
							border-bottom-color: $blue-lighter;
						}

						.nav-item {
							font-size: 15px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $black;

								&:hover {
									color: $orange;
								}
							}

							&.active {
								.nav-link {
									color: $orange;
								}
							}
						}
					}
				}
			}
		}
	}

	&.sticky {

		// menu
		.menu {
			@include media-breakpoint-up(xl) {
				.navbar-nav {
					>.nav-item {
						>.nav-link {
							color: $black;

							&:hover {
								color: $orange;
							}
						}

						&.active {
							>.nav-link {
								color: $orange;
							}
						}
					}
				}
			}
		}
	}

	// book-button
	.book-button {
		a {
			@extend .btn;
			@extend .btn-secondary;
			padding: 6px 20px !important;
			border-radius: 18px !important;
		}
	}
}
