// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$orange: #ca5817;
$orange-light: #f7cead;
$blue: #08519e;
$blue-light: #86a0b3;
$blue-lighter: #d5dae0;
$cream: #f1ebe7;
$white: #fff;
$black: #13161b;

// theme-colors
$primary: $orange;
$secondary: $blue;

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Radio+Canada:ital,wght@0,300..700;1,300..700&family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&display=swap');
$font-family-primary: 'Radio Canada', sans-serif;
$font-family-secondary: 'Sansita', sans-serif;

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.875; // 30px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;
$headings-line-height: 1;
$headings-color: $black;

// contextual
$light: $blue-lighter;
$dark: $blue-light;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
