// footer
.footer {

	// footer-outro
	.footer-outro {
		position: relative;
  		padding-top: calc(3vh + 30px);
		padding-bottom: 6vh;

		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						@include media-breakpoint-up(lg) {
							@include make-col(4);
						}

						@include media-breakpoint-up(xl) {
							@include make-col(5);
						}
					}

					&.two {
						@include media-breakpoint-up(lg) {
							@include make-col(8);
						}

						@include media-breakpoint-up(xl) {
							@include make-col(7);
						}
					}
				}
			}
		}

		// logo
		.logo {
			margin-bottom: 30px;

			a {
				display: block;

				img {
					width: 380px;

					@include media-breakpoint-down(lg) {
						width: 260px;
					}
				}
			}
		}

		// footer-link
		.footer-link {
			margin-top: 5px;

			ul {
				li {
					margin: 0 10px 10px 0;

					a {
						@extend .btn;
						@extend .btn-primary;
						font-size: 16px !important;
					}
				}
			}
		}

		// owners-login-badge
		div.owners-login-badge {
			a {
				position: absolute;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				width: 45px;
				padding: 24px 0;
				border-radius: 24px 0 0 24px;
				background-color: $black;
				color: $white;
				font-weight: 700;
				line-height: 45px;
				text-decoration: none;
				overflow: hidden;

				i {
					margin-bottom: 12px;
					font-size: 15px;
				}

				.list-item-title {
					writing-mode: vertical-lr;
					transform: scale(-1, -1);
					font-size: 13px;
				}

				&:hover {
					background-color: $orange;
				}
			}
		}
	}

	// footer-partners
	.footer-partners {
		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0 -15px;
			padding: 0;
			list-style: none;

			li {
				margin: 15px;
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		padding: 15px 0;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0;
			padding: 0;
			list-style: none;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}

			li {
				margin: 0 15px;
				font-size: 13px;

				a {
					color: $black;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

}
