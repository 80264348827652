// mini-sab
.mini-sab {
	position: relative;
	padding-bottom: 5px;
	background-color: $blue-lighter;

	&::before {
		content: url('/images/wave-top.svg');
		z-index: 10;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		margin-bottom: -1px;
		font-size: 0;
		line-height: 0;

		@include media-breakpoint-down(md) {
			content: "";
			background-image: url("/images/wave-top.png");
			background-size: cover;
			aspect-ratio: 1366 / 19;
		}
	}

	&::after {
		content: url('/images/wave-bottom.svg');
		z-index: 10;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		margin-top: -1px;
		font-size: 0;
		line-height: 0;

		@include media-breakpoint-down(md) {
			content: "";
			background-image: url("/images/wave-bottom.png");
			background-size: cover;
			aspect-ratio: 1366 / 51;
		}
	}

	.container {
		position: relative;
		display: flex;
		justify-content: center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: -30px 0 0 0;
			padding: 10px 5px 20px 5px;
			border-radius: 20px;
			background: $black;
			box-shadow: $shadow;
			color: #fff;

			@include media-breakpoint-down(md) {
				padding-top: 20px;
			}

			@include media-breakpoint-up(xl) {
				border-radius: 42px;
			}
		}
	}
}
