// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-detail-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin-top: calc(6vh + 30px);
	margin-bottom: 6vh;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin-right: 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// usps-section
// =========================================================================
.usps-section {
	margin: 6vh 0;

	@include media-breakpoint-up(lg) {
		margin-top: calc(6vh + 30px);
	}

	.container-holder {
		margin-left: 0 !important;
		margin-right: 0 !important;

		.column {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}

	.collection {
		&.owl-carousel {
			.owl-stage-outer {
				padding-top: 10px;

				.owl-item {

					.item {
						max-width: 300px;
						transition: 0.5s;
					}

					&:nth-child(5n+1) {
						.item {
							margin-top: 35px;
						}
					}

					&:nth-child(5n+2) {
						.item {
							margin-top: 10px;
						}
					}

					&:nth-child(5n+3) {
						.item {
							margin-top: 20px;
						}
					}

					&:nth-child(5n+4) {
						.item {
							margin-top: 0;
						}
					}

					&:nth-child(5n+5) {
						.item {
							margin-top: 25px;
						}
					}

					&:hover {
						.item {
							transform: translateY(-10px);
						}
					}

					.item {
						height: auto !important;

						.card {
							border: none;
							border-radius: 6px;
							background-color: $blue-lighter;
							overflow: visible;

							&[href]:hover {
								background-color: $cream;
							}

							.card-image {
								position: absolute !important;
								top: -10px;
								right: 15px;

								i {
									color: $black;
									font-size: 40px;
								}

								img {
									max-width: 60px;
								}
							}

							.card-body {
								@include media-breakpoint-up(xl) {
									padding: 20px;
								}

								.card-caption {
									.card-title {
										margin: 0 45px 5px 0;
										color: $orange;
										font-size: 18px;
									}

									.card-subtitle {
										margin: 0;
										font-size: 15px;
										font-family: $font-family-primary;
									}

									.card-description {
										p {
											margin-bottom: 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// intro-section
// =========================================================================
.intro-section {
	margin: 6vh 0;

	.container-holder {
		margin: 0;
		padding: 30px 15px;
		border-radius: 20px;
		background-color: $blue-lighter;
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// intro-collection-section
// =========================================================================
.intro-collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}
				}
			}
		}

		.card {
			border: none;
			border-radius: 20px;

			.card-img-overlay {
				border-radius: 0;
				background: rgba($black, 0.10);

				.card-caption {
					display: flex;
					align-items: flex-end;
					height: 100%;
					text-shadow: none;

					.card-title {
						margin: 0;
						@extend .mega-heading;
						writing-mode: vertical-lr;
					}

					.card-subtitle,
					.card-description {
						display: none;
					}
				}
			}
		}
	}
}

// highlight-section
// =========================================================================
.highlight-section {
	position: relative;
	padding: 20px 0;
	background-color: $blue-lighter;

	&::before {
		content: url('/images/wave-alt-top.svg');
		z-index: 10;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		margin-bottom: -1px;
		font-size: 0;
		line-height: 0;

		@include media-breakpoint-down(md) {
			content: "";
			background-image: url("/images/wave-alt-top.png");
			background-size: cover;
			aspect-ratio: 1366 / 19;
		}
	}

	&::after {
		content: url('/images/wave-alt-bottom.svg');
		z-index: 10;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		margin-top: -1px;
		font-size: 0;
		line-height: 0;

		@include media-breakpoint-down(md) {
			content: "";
			background-image: url("/images/wave-alt-bottom.png");
			background-size: cover;
			aspect-ratio: 1360 / 51;
		}
	}

	.column {
		&.one {
			@include media-breakpoint-up(xl) {
				text-align: right;
				@include make-col(5);
			}
		}

		&.three {
			@include media-breakpoint-up(xl) {
				@include make-col(3);
			}
		}
	}

	h2 {
		@include media-breakpoint-up(xl) {
			font-size: $h1-font-size;
		}
	}

	h6 {
		color: $orange;
	}
}

// tiles-collection-section
// =========================================================================
.tiles-collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.masonry {
			.card-columns {
				column-gap: 15px;

				@include media-breakpoint-down(sm) {
					column-count: 2;
				}

				.item {
					@include media-breakpoint-down(sm) {
						&:nth-child(6) {
							//padding-top: 60px;
						}
					}

					@include media-breakpoint-up(lg) {
						&:nth-child(4) {
							//padding-top: 200px;
						}

						&:nth-child(7) {
							//padding-top: 120px;
						}
					}

					.card {
						display: block;
						margin-bottom: 15px;
						border: none;
						border-radius: 20px;

						@include media-breakpoint-down(sm) {
							border-radius: 10px;
						}

						&::before {
							content: "";
							z-index: 1;
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							background: rgba($black, 0.10);
						}

						.card-img-overlay {
							z-index: 2;
							display: flex;
							flex-direction: column;
							padding: 20px;
							background: transparent;
							transition: 0.5s;

							@include media-breakpoint-down(sm) {
								padding: 10px;
							}

							.card-caption {
								display: flex;
								flex-direction: column;
								flex: 0;
								margin-top: auto;
								text-shadow: none;

								.card-title {
									order: 4;
									margin-bottom: 0;
									@include font-size($h2-font-size);

									@include media-breakpoint-down(sm) {
										font-size: 18px;
									}

									&:hover {
										color: $white;
									}
								}

								.card-subtitle {
									@include media-breakpoint-down(sm) {
										font-size: 16px;
									}
								}

								.card-description {
									@include media-breakpoint-down(sm) {
										font-size: 14px;
										line-height: 1.2;
									}
								}

								.card-subtitle,
								.card-description {
									height: 0;
									margin: 0;
									overflow: hidden;
									transition: 0.5s;
								}
							}

							.card-buttons {
								display: none;

								@include media-breakpoint-up(md) {
									height: 0;
									margin: 0;
									overflow: hidden;
									transition: 0.5s;
								}

								.card-btn {
									padding: 0 20px 0 0;
									color: $white;
									text-decoration: underline;
								}
							}
						}

						&:hover {
							.card-img-overlay {
								background: $blue;

								.card-caption {

									.card-subtitle {
										margin-bottom: 5px;
									}

									.card-subtitle,
									.card-description {
										height: auto;
									}

									.card-description {
										margin-bottom: 15px;
									}
								}

								.card-buttons {
									height: auto;
								}
							}
						}
					}
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;
	overflow: hidden;

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 300px;
		max-height: 720px;

		.item {
			position: relative;
			height: 75vh !important; // overide
			min-height: 300px;
			max-height: 720px;
			padding: unset; // overide

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($black, 0.1);
			}

			.owl-caption-holder {
				z-index: 100;

				.owl-container {
					.owl-caption {
						max-width: 720px;
						margin-right: auto;
						color: $orange-light;
						text-align: left;
						text-shadow: none;

						.owl-title {
							@extend .mega-heading;

							&:first-line {
								color: $white;
							}
						}
					}
				}
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				justify-content: flex-start;
			}
		}

		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	position: relative;
	margin: 6vh 0;
	padding-top: 30px;
	background-color: $blue-lighter;

	&::before {
		content: url('/images/wave-alt-top.svg');
		z-index: 10;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		margin-bottom: -1px;
		font-size: 0;
		line-height: 0;

		@include media-breakpoint-down(md) {
			content: "";
			background-image: url("/images/wave-alt-top.png");
			background-size: cover;
			aspect-ratio: 1366 / 19;
		}
	}

	&::after {
		content: url('/images/wave-alt-bottom.svg');
		z-index: 10;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		margin-top: -1px;
		font-size: 0;
		line-height: 0;

		@include media-breakpoint-down(md) {
			content: "";
			background-image: url("/images/wave-alt-bottom.png");
			background-size: cover;
			aspect-ratio: 1360 / 51;
		}
	}

	h2 {
		margin-bottom: 30px;
	}
}

&.accommodation-detail {
	.booking-section {
		margin-bottom: 0;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
