// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 250px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 300px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 90vh;
	}

	@include media-breakpoint-up(xl) {
		min-height: 540px;
		height: 100vh;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	// owl-carousel
	.owl-carousel {
		.item {
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($black, 0.25);
			}

			.owl-caption-holder {
				z-index: 100;

				.owl-container {
					.owl-caption {
						max-width: 720px;
						margin-right: auto;
						color: $orange-light;
						text-align: left;
						text-shadow: none;

						.owl-title {
							@extend .mega-heading;

							&:first-line {
								color: $white;
							}
						}
					}
				}
			}
		}
	}

	// owl-dots
	.owl-nav,
	.owl-dots {
		display: none;
	}

	// anwb-badge
	div.anwb-badge {
		z-index: 100;
		position: absolute;

		@include media-breakpoint-down(lg) {
			right: 10px;
			bottom: 50px;
		}

		@include media-breakpoint-up(xl) {
			top: 50%;
			right: 40px;
			transform: translateY(-50%);
		}

		ul {
			display: flex;

			@include media-breakpoint-up(xl) {
				flex-direction: column;
			}

			li {
				margin: 5px;

				img {
					display: block;

					@include media-breakpoint-down(lg) {
						width: 82px;
					}
				}

				.list-item-title {
					display: none;
				}
			}
		}
	}
}

&:not(.home) {
	.eyecatcher {
		.owl-carousel {
			.item {
				align-items: flex-end;
				padding-bottom: 100px;
			}
		}
	}
}
